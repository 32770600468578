import React from 'react'
import { useNavigate } from 'react-router-dom';
import Lottie from 'react-lottie';
import animationData from '../animationData.json';
const Pagenotfound = () => {
    let navigate = useNavigate();
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };
  return (
        <>
            <div className='container-fluid' style={{height:"100vh", overflow:'hidden',fontFamily:'sans-serif'}}>
                <div className='row'>
                    <div className='col pt-5'>
                        <Lottie options={defaultOptions} height='80%' width='50%' />
                        <div className='text-center mt-0 pt-0 martop'>
                            <button onClick={() => { navigate("/home") }} className='btn btn-lg text-white px-5 BTN_Color'>Back to Home</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
  )
}
export default Pagenotfound