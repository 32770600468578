import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify"
import axios from 'axios';
import { handleError } from "../Utilities/HandleError";
import { useParams } from "react-router-dom";
import { useForm } from 'react-hook-form';

function TransporterBankDetails() {
  let navigate =useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors }, getValues
  } = useForm();
  //sumbit 
  const [loading, setLoading] = useState("")
  const [searchParams, setSearchparams] = useState()
  const { id } = useParams()
  useEffect(() => {
    if (id) {
      setSearchparams(id)
    }
    bank()
  }, [])
  const onSubmit = (data) => {
    console.log(data)
    setLoading(true)
    let formData = new FormData()  
      formData.append("public_id", searchParams);
      formData.append("bank_name",data.bank_name);
      formData.append("ifsc_number",data.ifsc_number);
      formData.append("account_number",data.account_number);

    let url = process.env.REACT_APP_BASEURL + "vendor/bank/detail/";
    const config = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-type": "application/json",
        "Authorization": `bearer ` + localStorage.getItem('adroit_token'),
      },
    };
    axios
      .post(url, formData, config)
      .then((res) => {
        setLoading(false)
        toast.success(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 5000,
          theme: "colored",
        });
        if (res.data.status === 201) {
          navigate("/success")
        }
      })
      .catch((err) => {
        setLoading(false)
        const getErrRes = handleError(err);
        if (getErrRes === 401) {
          toast.error("Something went wrong.", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 4000,
            theme: "colored",
          });
        } else {
          toast.error(getErrRes ? getErrRes : "Something went wrong.", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 4000,
            theme: "colored",
          });
        }
      });
  };



const [bankList, setbankList] = useState([]);
  const bank = () => {
    let url = process.env.REACT_APP_BASEURL + "banks/";
    const config = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-type": "application/json",
        "Authorization": `bearer ` + localStorage.getItem('adroit_token'),
      },
    };

    axios.get(url, config).then((res) => {
      if(res.data.data){
        setbankList(res.data.data)
      }

    }) .catch((err) => {
        setLoading(false)
        const getErrRes = handleError(err);
        if (getErrRes === 401) {
          toast.error("Something went wrong.", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 4000,
            theme: "colored",
          });
        } else {
          toast.error(getErrRes ? getErrRes : "Something went wrong.", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 4000,
            theme: "colored",
          });
        }
      });
  }
  return (
    <>
    <div className="container mt-5" style={{fontFamily:'sans-serif'}}>
      <div className="row d-flex justify-content-center align-items-center">
      <div className="text-center">
          <h2>Enter Your Bank Account Details</h2>
        </div>
        <div className="col-8">
          <div className="card p-5 mb-5">
            <form>
              <div className="mb-3">
                <label className="form-label">Select Bank</label>
                <select
                  className="form-select font-18"
                  aria-label="select a date"
                  style={{ width: "100%" }}
                >
                   <option selected disabled>Select Bank</option>
                   {
                    bankList?.length > 0 && bankList.map((item) => {
                      return (
                   <option>{item.full_name}</option>

                      )
                    })
                   }
                </select>
              </div>
              <div className="mb-3">
                <label className="form-label">Enter Account Number</label>
                <input  type="text" name="account_number"
                   {...register("account_number", { required: true })}
                placeholder="Enter Account Number"
                 className="form-control"/>
                  {errors.account_number?.type === "required" && (
                      <p className="text-danger">
                        Account Number is required!
                      </p>
                    )}
              </div>
              <div className="mb-3">
                <label className="form-label">Re-Enter Account Number</label>
                <input type="text" name="reaccount_number"
                   {...register("reaccount_number", { required: true,
                    validate: (value) => {
                      const { account_number } = getValues();
                      return (
                        account_number === value || "Passwords should match!"
                      );
                    },
                  })}
                placeholder="Re-Enter Account Number"
                 className="form-control"/>
                  {errors.reaccount_number?.type === "required" && (
                      <p className="text-danger">
                        Re-Enter Account Number is required!
                      </p>
                    )}
                     {errors.reaccount_number?.type === "validate" && (
                      <p className="text-danger">
                        Please enter valid account number!
                      </p>
                    )}
              </div>
              <div className="mb-3">
                <label className="form-label">Enter IFSE Code</label>
                <input type="text" name="ifsc_number"
                   {...register("ifsc_number", { required: true })}
                placeholder="Enter IFSE Code"
               className="form-control"/>
               {errors.ifsc_number?.type === "required" && (
                  <p className="text-danger">
                    IFSE Code is required!
                  </p>
                )}
              </div>
              <div className="d-flex justify-content-between mt-4">
                <button onClick={()=>{navigate('/transportercontactdetails')}}  className="btn w-25 text-white BTN_Color">Prev</button>
                {loading ? <button onClick={handleSubmit(onSubmit)} className='btn w-25 text-white BTN_Color' disabled> <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Submiting...</button> :
                    <button onClick={handleSubmit(onSubmit)} className='btn w-25 text-white BTN_Color' >Submit</button>}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    </>
  );
}

export default TransporterBankDetails;
