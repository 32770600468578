import React from 'react'
import image from "../assets/img/logo.png";
import success from "../assets/img/success.png";
import { useNavigate } from "react-router-dom";
import moment from 'moment';

const TransporterAddSuccess = () => {
  let navigate =useNavigate()
  const currentDate = new Date();
  return (
    <>
      <div className='container mt-5' style={{fontFamily:'sans-serif'}}>
        <div className='row d-flex justify-content-center align-items-center'>
          <div className='col-8'>
            <div className='card mt-5 border-0 shadow text-center'>
              <div className="container d-flex justify-content-center">
                <div className="img-holder">
                    <img
                    alt="logo"
                    className="img" style={{ borderRadius: "50%" }}
                    src={image}
                  />
                </div>
              </div>
              <div className='mt-3'>
                <h5 className='text-info'>You have Signup Successfully!</h5>
                <h6 className='text-secondary'>Transporter Added Successfully, we will
                review your profile soon.</h6>
              </div>
                <div className="img-holder">
                    <img
                    alt="logo"
                    className="imged" style={{ borderRadius: "50%" }}
                    src={success}
                  />
                </div>
              <div className='mt-5'>
                <h5 className='text-black'>Welcome To BidEase</h5>
                <small className='text-secondary'>Completed on {moment(currentDate.toString()).format('DD-MM-YY, LT')}</small>
              </div>
              <span className='my-3'>Get Started With</span>
              <div className='my-3'>
                <button className='btn text-white BTN_Color' onClick={()=>{navigate('/home')}} >Back to Dashboard</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default TransporterAddSuccess