import React, { useState, useEffect } from "react";
import DatePicker from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import moment from 'moment';

function TimeDate() {
    const currentDate = moment();
    const [endTime, setEndTime] = useState(new Date());
    const maxTime = moment(currentDate).add(8, 'hours');

    const calculateMaximumDate = () => {
        setEndTime((prevEndTime) => {
            if (prevEndTime.isAfter(maxTime)) {
                return maxTime;
            }
            return prevEndTime;
        });
    };

    useEffect(() => {
        calculateMaximumDate();
    }, []);

    const isValidDate = (current) => {
        return current.isAfter(currentDate, 'day');
    };

    const isValidTime = (current) => {
        return current.isBetween(moment(), maxTime, 'minutes', '[]');
    };

    return (
        <>
            <DatePicker
                value={endTime}
                dateFormat="DD-MM-YYYY"
                timeFormat="hh:mm A"
                isValidDate={isValidDate} // Disable past dates
                isValidTime={isValidTime} // Limit time to 1 to 8 hours from current time
                onChange={(date) => setEndTime(date)}
            />
        </>
    );
}

export default TimeDate;
