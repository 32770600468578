import React from 'react'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
const PopupTableskeleton = () => {
  return (
    <>
      <tr>
        <th><Skeleton height={100} /></th>
        <th><Skeleton height={100} /></th>
        <th><Skeleton height={100} /></th>
        <th><Skeleton height={100} /></th>
      </tr>
      <tr>
        <th><Skeleton height={100} /></th>
        <th><Skeleton height={100} /></th>
        <th><Skeleton height={100} /></th>
        <th><Skeleton height={100} /></th>
      </tr>
      <tr>
        <th><Skeleton height={100} /></th>
        <th><Skeleton height={100} /></th>
        <th><Skeleton height={100} /></th>
        <th><Skeleton height={100} /></th>
      </tr>
    </>
  )
}

export default PopupTableskeleton