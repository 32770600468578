import React, { useState, useEffect } from 'react'
import { Card, Col, Container, Row } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom';
import { toast } from "react-toastify"
import axios from 'axios';
import image from "../assets/img/placeholder.jpeg"
import { useForm } from 'react-hook-form';
import { handleError } from "../Utilities/HandleError";

function MyProfile() {
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const [profiles,setProfiles]=useState({});

    useEffect(() => {
        getprofileDetails()
      }, []);

    const profileDetails =(data)=>{
        console.log(data);
    }

    const getprofileDetails = () =>{
        let url = process.env.REACT_APP_BASEURL + `profile/`
        const config = {
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Content-type": "application/json",
                "Authorization": `bearer ` + localStorage.getItem('adroit_token'),
              }
          };
          axios
            .get(url, config)
            .then((res) => {
                setProfiles(res.data.data);              
            })
            .catch((err) => {
                const getErrRes = handleError(err);
                if (getErrRes === 401) {
                  toast.error("Something went wrong.", {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 4000,
                    theme: "colored",
                  });
                } else {
                  toast.error(getErrRes ? getErrRes : "Something went wrong.", {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 4000,
                    theme: "colored",
                  });
                }
            });
    };
    return (<>
        <Container className='mx-auto d-flex justify-content-center flex-column mb-2' style={{ height: "80vh",fontFamily:'sans-serif' }} >
            <Row className='h-100 align-items-center d-flex justify-content-center ' >
                <Col lg={6}>
                    <Card className='ps-5 pe-5 py-3 px-3' >
                        <h2 className='text-center mt-2'>Profile Details</h2>
                        <Card.Body>
                            {/* {JSON.stringify(sendImage)} */}
                            <form encType='multipart/form-data'
                            // onSubmit={(e) => { handleSubmit(e) }}
                            >
                                <div className="container  ">
                                    <div className='d-flex justify-content-center'>
                                        <div className="img-holder">
                                            {/* {image !== null ?
                                                <img
                                                    alt="Short logo"
                                                    className="img" style={{ borderRadius: "50%" }}
                                                    src={image}
                                                />
                                                :
                                                socialImage !== null ?
                                                    <img
                                                        alt="Short logo"
                                                        className="img" style={{ borderRadius: "50%" }}
                                                        src={socialImage}
                                                    /> :
                                                    <img
                                                        alt="Short logo"
                                                        className="img" style={{ borderRadius: "50%" }}
                                                        src={require('../../assets/images/placeholder.png')}
                                                    />} */}
                                            <img
                                                alt="logo"
                                                className="img" style={{ borderRadius: "50%" }}
                                                src={image}
                                            />
                                        </div>

                                    </div>
                                    <input type="file" accept="image/*" name="image-upload" id="input"
                                    // onChange={imageHandler}
                                    />
                                    <div className="label">
                                        <label className="image-upload text-green-500 font-bold" htmlFor="input">
                                            <i className="material-icons "></i>Choose your Photo</label>
                                    </div>
                                </div>
                                <label className="form-label">Full name</label>
                                <input className="form-control" value={profiles.name}
                                    type='text' name='name' placeholder='Enter your full name '
                                    {...register("name", {
                                        required: true,
                                    })}
                                />
                                {errors?.name?.type === "required" && (
                                    <p className="text-danger">
                                        This field is required
                                    </p>
                                )}
                                 <label className="form-label mt-2">Email</label>
                                <input className="form-control" value={profiles.email}
                                    type='email' name='email' placeholder='Enter your email address'
                                    {...register("email", {
                                        required: true,
                                        pattern:
                                            /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                                    })}
                                />
                                {errors?.email?.type === "required" && (
                                    <p className="text-danger">
                                        This field is required
                                    </p>
                                )}
                                {errors?.email?.type === "pattern" && (
                                    <p className="text-danger">
                                        Invalid email address
                                    </p>
                                )}
                                <label className="form-label mt-2" >Mobile number</label>
                                <input className="form-control" type='text' maxLength={10} name='phone' placeholder='Enter 10 digit mobile number' value={profiles.mobile_number}
                                {...register("phone", {
                                    required: true,
                                    pattern: /(0|91)?[6-9][0-9]{9}/,
                                })}
                                />
                                 {errors?.phone?.type === "required" && (
                                    <p className="text-danger">
                                        This field is required
                                    </p>
                                )}
                                {errors?.phone?.type === "pattern" && (
                                    <p className="text-danger">
                                        Invalid mobile number
                                    </p>
                                )}

                                <Row className='d-flex justify-content-center mt-4'>
                                    {/* {loading ? <button type='submit' className='btn text-white col-8' style={{ backgroundColor: "#C8811A" }} disabled> <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Updating...</button> :
                                        <button disabled={isSocial ? true : false} type='submit' className='btn text-white col-8' style={{ backgroundColor: "#C8811A" }}>Update</button>}

                                    {isSocial ?
                                        <small className='text-center text-danger'>Profile update unavailable in social login </small>
                                        : null
                                    } */}
                                    <button disabled onClick={handleSubmit(profileDetails)} className='btn text-white col-8 BTN_Color'>Update</button>
                                </Row>
                            </form>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>           
        </Container>
    </>)
}

export default MyProfile;
