import React from "react";
import classnames from "classnames";
import { usePagination, DOTS } from "./usePagination";
import "../Pagination/pagination.css";
import { Link } from "react-router-dom";
const CustomPagination = (props) => {
  const {
    onPageChange,
    onPageSizeChange,
    totalCount,
    siblingCount = 1,
    currentPage,
    pageSize,
    className,
  } = props;

  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  });

  // if (currentPage === 0 || paginationRange.length < 2) {
  //   return null;
  // }

  const onNext = () => {
    if (paginationRange[5] !== currentPage) onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    if (currentPage !== 1) onPageChange(currentPage - 1);
  };
  let lastPage = paginationRange[paginationRange.length - 1];
  return (
    <>
      <div className="row">
        <div className="col-6 mt-2">
          <div className="pagiloc">
            <nav aria-label="Page navigation example">
              <nav aria-label="Page navigation example">
                <ul
                  className={classnames(
                    "pagination-container pagination justify-content-start",
                    {
                      [className]: className,
                    }
                  )}
                >
                  <li
                    className={classnames("page-item", {
                      disabled: currentPage === 1,
                    })}
                    onClick={onPrevious}
                  >
                    <Link
                      className={classnames("page-link", {
                        textprimary: currentPage !== 1,
                      })}
                    >
                      Prev
                    </Link>
                  </li>

                  {paginationRange.map((pageNumber, index) => (
                    <span key={index}>
                      {pageNumber === DOTS ? (
                        <>
                          <li key={pageNumber} className="page-item dots">
                            {" "}
                            &#8230;
                          </li>
                        </>
                      ) : (
                        <li
                          className={classnames("page-item", {
                            active: pageNumber === currentPage,
                          })}
                          onClick={() => onPageChange(pageNumber)}
                        >
                          <Link
                            className={classnames(
                              "page-link",
                              {
                                textWhite: pageNumber === currentPage,
                              },
                              {
                                textprimary: pageNumber !== currentPage,
                              }
                            )}
                          >
                            {" "}
                            {pageNumber}
                          </Link>
                        </li>
                      )}
                    </span>
                  ))}
                  <li
                    className={classnames("page-item", {
                      disabled: currentPage === lastPage,
                    })}
                    onClick={onNext}
                  >
                    <Link
                      className={classnames(
                        "page-link",
                        {
                          disabled: currentPage === lastPage,
                        },
                        {
                          textprimary: currentPage !== lastPage,
                        }
                      )}
                    >
                      Next
                    </Link>
                  </li>
                </ul>
              </nav>
            </nav>
          </div>
        </div>
        <div className="col-6 mt-2">
          <div className="pagidrop">
            Rows per page :
            <select
              className=" ms-1 p-1 rounded bg-white border-1"
              onChange={(e) => {
                onPageSizeChange(e.target.value);
              }}
              value={pageSize}
            >
              {/* <option value="10">10</option> */}
              <option value="20">20</option>
              {/* <option value="50">50</option> */}
              {/* <option value="100">100</option> */}
              {/* <option value="200">200</option> */}
              <option value={totalCount}>All</option>
            </select>
          </div>
        </div>
      </div>
    </>
  );
};

export default CustomPagination;
