import React from 'react'

export const Footer = () => {
  return (
    <>
    <div className='container-fluid bg-dark w-100 mt-5' style={{fontFamily:'sans-serif'}}>
      <div className='row'>
        <div className='col'>        
            <h5 className='text-center text-white p-3'>Copyright@ 2023.All Rights Reserved.</h5>         
        </div>
      </div>
    </div>
       
    </>
  )
}
